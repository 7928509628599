var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"90%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-api")])],1),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.apiHasErrors),expression:"apiHasErrors"}],attrs:{"small":"","color":"warning"}},[_vm._v("mdi-alert-circle-outline")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"elevation-10",attrs:{"rounded":""}},[_c('v-card-title',[_vm._v("API LOG "),_c('v-spacer'),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.apiInfo.length),expression:"apiInfo.length"}],attrs:{"text":"","plain":""},on:{"click":function($event){$event.stopPropagation();return _vm.resetApiInfo.apply(null, arguments)}}},[_vm._v("clear log")]),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1)],1),_c('v-divider',{staticClass:"mb-4"}),_c('v-card-text',[_c('v-data-table',{attrs:{"item-key":"apiCall.sequenceId","hide-default-footer":"","disable-sort":"","disable-pagination":"","height":"400","fixed-header":"","items":_vm.apiInfo,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.apiCall.error",fn:function(ref){
var item = ref.item;
return [(item.apiCall.error)?_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("mdi-alert-circle-outline")]):_vm._e()]}},{key:"item.apiCall.response",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.copyToClipboard(item.apiCall.response)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("copy response to clipboard")])])]}}],null,true)})],1),_c('v-card-text',[_c('v-sheet',{staticClass:"px-4",staticStyle:{"overflow-y":"auto"},attrs:{"outlined":"","max-height":"200"}},[_c('v-sparkline',{attrs:{"value":this.apiSparkLine.values,"line-width":"1","label-size":"2","height":"30"},scopedSlots:_vm._u([{key:"label",fn:function(item){return [_vm._v(" "+_vm._s(item.value)+"ms ")]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }