import axios from "axios";
import store from "@/store";

const baseURL = "https://api.caas.be/cavallo/web";
const apiClient = axios.create({
    baseURL,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

async function agentLogin() {
    var webSessionId = store.getters["session/webSessionId"];
    var environment = store.getters["session/environment"];
    var apiKey = "";
    if (!webSessionId) {
        switch (environment) {
            case "LIVE":
                apiKey = process.env.VUE_APP_CAAS_API_KEY;
                break;
            case "TEST":
                apiKey = process.env.VUE_APP_CAAS_API_KEY_TEST;
                break;
        }
        var request = {
            request: {
                rpcName: "agentLogin",
                params: { apiKey: apiKey },
            },
        };
        try {
            var response = await apiClient.post("/rpc", request);
            if (response.data.error) throw response.data.error;
            webSessionId = response.data.response.data.sessionId;
            store.dispatch("session/setWebSessionId", webSessionId);
        } catch (e) {
            throw "The erp service is unavailable: " + e;
        }
    }
    return webSessionId;
}

export default {
    async rpc(rpcName, params) {
        // check existence of params
        if (!params) params = {};

        // inject sessionId
        params.sessionId = await agentLogin();

        if (rpcName === "agentLogin") return;

        // inject userId
        if ("webUserId" in params) {
            params.webUserId = store.getters["session/webUserId"];
        }

        // inject languageId
        if ("languageId" in params) {
            params.languageId = store.getters["session/currentLanguageId"];
        }

        // build request
        var request = {
            request: {
                //environment: process.env.NODE_ENV,
                rpcName,
                params,
            },
        };

        // api call
        var t0 = performance.now();
        var response = await apiClient.post("/rpc", request);
        var t1 = performance.now();

        // logging
        if (store.getters["session/role"] === "DEV") {
            var result, error;
            if (response.data.error) {
                result = response.data.error;
                error = true;
            } else {
                result = response.status + " " + response.statusText;
                error = false;
            }
            const apiInfo = {
                apiCall: {
                    timeStamp: new Date(),
                    rpcName: request.request.rpcName,
                    params: JSON.stringify(request.request.params),
                    result: result,
                    error: error,
                    response: JSON.stringify(response.data),
                    duration: Math.round(t1 - t0),
                },
            };
            store.dispatch("session/setApiInfo", apiInfo);
        }

        // error handling
        if (response.data.error) {
            let error =
                "Error calling " + rpcName + " : " + response.data.error;

            throw error;
        }
        return response.data.response.data;
    },
};
